<template>
    <div>        
        <div class="container">
            <div  width="100%" style="margin-top:-43px;">
                <!-- <div class="section">
                    <span  class="title"><i class="el-icon-date"></i> 资源检索</span>
                </div> -->
                <el-row>
                <el-col :span=24>
                    <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="资源套餐" name="first"> -->
                            <el-form  ref="form" :model="searchForm" label-width="80px" style="margin-top:20px;" label-position="left">
                                <el-row>
                                    <search-old @onchange="changeTag" :type="1" @setfinished="setFinished"/>
                                </el-row>
                                <!-- <el-row>
                                    <el-form-item label="省份/车站">
                                        <el-cascader 
                                                :show-all-levels="false"                                
                                                placeholder="请选择省份/车站"
                                                :options="areaStationList"
                                                :props="props"
                                                v-model="searchForm.stationId"  
                                                clearable                       
                                        ></el-cascader>
                                    </el-form-item>
                                    
                                    <el-form-item label="关键字"  style="margin-left:30px;">
                                            <el-input  v-model="searchForm.key" placeholder="请输入关键字"></el-input>
                                    </el-form-item>
                                    <el-button style="margin-left:30px;height: 20px;" type="primary" @click="onMediaGroupQuery">检索</el-button>
                                </el-row> -->
                            </el-form>
                            <!-- <div style="display:flex;"><div style="margin-top:15px;color:grey;">媒体上刊率</div><div style="margin-top:-10px;"><el-image style="padding-top:15px;" :src="require('@/assets/img/legend.png')"></el-image></div></div> -->
                                <div style="display:flex;justify-content:flex-end;">
                                    <div class="legendImg" style="margin-top:-10px;"><el-image style="padding-top:15px;" width="200px;" :src="require('@/assets/img/legend.png')"></el-image></div>
                                </div>
                                <!-- <div style="display:flex;justify-content:space-between ;">
                                   <el-pagination
                                            @size-change="handleMediaSizeChange"
                                            @current-change="handleMediaCurrentChange"
                                            :current-page="mediaGroup.currentPage"
                                            :page-sizes="[20,50, 100]"
                                            :page-size="mediaGroup.pageSize"
                                            layout="total, sizes, prev, pager, next, jumper"
                                            :total="dataTableList.length">
                                    </el-pagination>                                 
                                </div> -->
                                <el-table
                                    v-if="finished"
                                    ref="multipleTable"
                                    :data="dataTableList.slice((mediaGroup.currentPage-1)*mediaGroup.pageSize,mediaGroup.currentPage*mediaGroup.pageSize)"                            
                                    border
                                    resizable      
                                    :row-key="getRowKey"                              
                                    :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                                    style="width: 100%;margin-top: 20px;font-size: x-small;"
                                    @selection-change="handleSelectionChange">	
                                    <el-table-column :selectable="checkbox_select"  type="selection" reserve-selection width="55"></el-table-column>	
                                    <el-table-column prop="area" align="center"  width="100"  sortable label="大区/路局"></el-table-column>
                                    <el-table-column prop="province" align="center"  width="100"   label="省份/列车大类"></el-table-column>
                                    <el-table-column prop="medias" align="center"   width="220"  sortable label="站点/车型">
                                        <!-- :show-overflow-tooltip="station" -->
                                        <template v-slot="scope">    
                                            <!-- <div v-if="scope.row.mediaUrl">         
                                                <el-link type="primary" :href="scope.row.mediaUrl" target="_blank">{{scope.row.station}}</el-link>
                                            </div>
                                            <div v-else>
                                                <el-link type="info" disabled>{{scope.row.station}}</el-link>
                                            </div> -->
                                            <div v-if="scope.row.stationId!=='0'" style="color: #409EFF;cursor: pointer;"  @click="openStation(scope.row.station)"><span style="font-size:x-large">{{scope.row.station}}</span></div>
                                            <div v-else><span style="font-size:large">{{scope.row.station}}</span></div>
                                            <!-- <el-button type="text" @click="openStation(scope.row.station)">{{scope.row.station}}</el-button>                                     -->
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="typeName" align="center"   width="80"  label="资源类型"></el-table-column>
                                    <el-table-column prop="position" align="center"   width="80"  label="位置"></el-table-column>
                                    <el-table-column prop="name" align="center"   width="120" sortable label="套餐名"></el-table-column>
                                    <!-- <el-table-column prop="mediaLevel" align="center"   width="50"  label="等级"></el-table-column> -->
                                    <el-table-column prop="medias" align="center"   sortable label="媒体资源(尺寸m*m/面积㎡)">
                                        <template v-slot="scope">
                                            <!-- <div class="group-item" v-for="(item,index) in scope.row.mediaInfos" :key="index">
                                                <div v-if="item.images.length>0" style="color: #409EFF;cursor: pointer;" @click="ViewImage(item.images)">{{item.mediaCode}}</div>
                                                <div v-else>{{item.mediaCode}}</div>
                                            </div> -->
                                            <div v-if="scope.row.collapse==-1"><!-- 无需折叠 -->
                                                <div class="group-item" v-for="(item,index) in scope.row.mediaInfos" :key="index">
                                                    <div v-if="item.images.length>0" style="color: #409EFF;cursor: pointer;" @click="ViewImage(item.images)">{{item.mediaCode}}</div>
                                                    <div v-else>{{item.mediaCode}}</div>
                                                </div>
                                            </div>
                                            <div v-else-if="scope.row.collapse==1"><!-- 折叠状态 -->
                                                <div class="group-item" v-for="(item,index) in scope.row.mediaInfos" :key="index">
                                                    <div v-if="index<4">
                                                        <div v-if="item.images.length>0" style="color: #409EFF;cursor: pointer;" @click="ViewImage(item.images)">{{item.mediaCode}}</div>
                                                        <div v-else>{{item.mediaCode}}</div>                                                
                                                    </div>
                                                </div>
                                                <i class="el-icon-arrow-down" style="cursor:pointer;" @click="onCollapse(scope.row)"></i>
                                            </div>
                                            <div v-else><!-- 展开状态 -->
                                                <div class="group-item" v-for="(item,index) in scope.row.mediaInfos" :key="index">
                                                    <div v-if="item.images.length>0" style="color: #409EFF;cursor: pointer;" @click="ViewImage(item.images)">{{item.mediaCode}}</div>
                                                    <div v-else>{{item.mediaCode}}</div>                                            
                                                </div>
                                                <i class="el-icon-arrow-up" style="cursor:pointer;" @click="onCollapse(scope.row)"></i>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="totalCount" align="center"  width="50"  label="资源数量"></el-table-column>
                                    <el-table-column prop="medias" align="center"   width="110"  label="刊例(万元)">
                                        <template v-slot="scope">
                                            <div v-if="scope.row.liechePrice">
                                                <!-- {{ scope.row.liechePrice }} -->
                                                <div v-for="(item,index) in scope.row.liechePrice" :v-bind="index">
                                                    <span style="font-weight: bold;">{{ item.lenTitle }}</span>
                                                    <div v-for="(itemsub,indexsub) in item.priceItem">
                                                        {{ itemsub.timeTitle?itemsub.timeTitle+':':'' }}{{ itemsub.price }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div v-if="scope.row.cost && scope.row.cost>0">
                                                    {{scope.row.cost}}
                                                </div>
                                                <div v-else>
                                                    <div v-if="scope.row.cost15">{{scope.row.cost15}}(/15秒)</div>
                                                    <div v-if="scope.row.cost10">{{scope.row.cost10}}(/10秒)</div>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="GDP" align="center"  width="80"  label="城市GDP(亿)"></el-table-column>
                                    <!-- <el-table-column prop="remark" align="center" label="说明"></el-table-column> -->
                                    <el-table-column prop="useRate" align="center" width="100px;"  sortable label="上刊率">
                                        <template v-slot="scope">
                                            <div  class="prog-ele">                                                
                                                <el-progress :width="66" :height="66" type="circle" :percentage="scope.row.useRate" :status="scope.row.useRate>=80?'exception':scope.row.useRate<80 && scope.row.useRate>=50?'success':scope.row.useRate<50 && scope.row.useRate>=30?'':'warning'">{{scope.row.useRate}}</el-progress>
                                                <div v-if="!scope.row.existMonthRate" style="color:red;">*未最新导入上刊率</div>
                                            </div>
                                        </template>                                        
                                    </el-table-column>
                                    <el-table-column prop="id" align="center" label="操作" width="80">
                                        <template v-slot="scope">
                                            <div style="display:flex;">
                                                <el-button :disabled="scope.row.useRate==100 || !scope.row.existMonthRate || scope.row.saleStatus!='正常'" style="float: right; padding: 3px 0;font-size: x-small;" type="text" @click="onSetPlan(scope.row,0)">加入购物车</el-button>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    
                                </el-table>  
                            <!-- </el-scrollbar> -->
                            <div style="display:flex;justify-content:space-between ;">
                                <div v-if="selectedList?.length>1">
                                    <el-button style="float: right; padding: 3px 0;font-size: x-small;" type="text" @click="onSetPlan({},1)">批量加入购物车(已选中{{selectedList.length}}个套餐)</el-button>
                                </div>
                                <el-pagination
                                            @size-change="handleMediaSizeChange"
                                            @current-change="handleMediaCurrentChange"
                                            :current-page="mediaGroup.currentPage"
                                            :page-sizes="[20,50, 100]"
                                            :page-size="mediaGroup.pageSize"
                                            layout="total, sizes, prev, pager, next, jumper"
                                            :total="dataTableList.length">
                                </el-pagination>            
                                
                            </div>
                        <!-- </el-tab-pane>
                       
                        
                    </el-tabs> -->
                </el-col>
                
                </el-row>
                <el-dialog title="设置投放计划" v-model="setPlanVisible" width="40%" @open="open()"
                                        :modal-append-to-body='false'
                                        :close-on-click-modal='false'
                                        v-if="setPlanVisible"
                                        append-to-body>
                    <set-plan @close="setPlanVisible=false" :cur-item="curItem" :table-data="planData" :selected-list="toPlanList"/>
                                            <!-- :group-media-list="groupDetailMediaList" -->
                </el-dialog>

                <el-dialog title="车站介绍" v-model="viewStationVisible" width="80%"  @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    v-if="viewStationVisible"
                                    append-to-body>
                    <div style="height:2800px;">
                        <station @close="viewStationVisible=false" :station="station"/>
                    </div>
                </el-dialog>   

                <el-dialog title="资源图片" v-model="viewMediaVisible" width="40%" @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    append-to-body>
                    <media-detail @close="viewMediaVisible=false" :image-data="imageList"/>
                </el-dialog>


            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
// import SmartResult from '@/views/Business/SmartResult.vue';
import {SaveProjectSmart,ProjectExport} from "@/api/operate"
import {getToken} from "@/utils/auth";
// import SelectMedia from '@/views/Business/SelectMedia.vue';
// import SelectProduct from './SelectProduct.vue';
// import SelectMediaPackage from '@/views/Business/SelectMediaPackage.vue';
import SetPlan from '@/views/Business/Smart/SetPlan';
import SearchOld from '@/components/SearchOld';
import Station from '@/views/Business/Station';
import MediaDetail from '@/views/Business/Smart/MediaDetail';

export default {
    name: 'baseform',
    components:{SetPlan,SearchOld,Station,MediaDetail},
    // props: ['tagIds'],
    computed: {...mapGetters(['GetGroupList_ByTag','areaStationList','GetGroupDetail'])},
    data() {
        return {
            mediaGroup:{
                // 当前页
                currentPage: 1,
                // 每页多少条
                pageSize: 20,
            },
            searchForm:{
                stationId:-1,
                typeArr:[],
                // key:'',
                packageId:[],
                productKey:'',
            },
            setPlanVisible:false,
            planData:[],
            curItem:{},
            tagIds:'',
            key:'',
            station:'',
            isCityAnd:false,
            viewStationVisible:false,

            viewMediaVisible:false,
            imageList:[],

            finished:false,
            dataTableList:[],
            selectedList:[],
            toPlanList:[],//传递到set plan页面的套餐列表

        };
    },

    // watch: {
    //     tagIds: function () {
    //         // this.localPrice = this.discountPrice
    //         this.onMediaGroupQuery();
    //     }
    // },

    
    async mounted() {
      await this.$store.dispatch('operate/areaStationList');
    //   await this.$store.dispatch('operate/categoryList');
    //   await this.$store.dispatch('operate/mediaTypeList');
    //   await this.$store.dispatch('operate/packageList');
      

        // const s = document.createElement('script');
        // s.type = 'text/javascript';
        // s.src = 'http://pv.sohu.com/cityjson?ie=utf-8&ver='+Math.random();
        // document.body.appendChild(s);

        // setTimeout(() => {
            // this.onMediaGroupQuery();
        // },2000)

        
        // this.onProductQuery();
    },

    

    // watch:{
    //     curItem(newValue){
    //         console.log('newValue',newValue)
    //         if (newValue && newValue.haveSelectList)
    //             this.haveSelectCnt = newValue.haveSelectList.length;
    //         else
    //             this.haveSelectCnt=0;
    //     }
    // },

    methods: {
        changeTag(obj,key,station,isCityAnd){
            this.tagIds=obj;
            this.key=key;
            this.station=station;
            this.isCityAnd=isCityAnd;
            this.$refs.multipleTable.clearSelection();
            if ((obj=='' || obj== ";") && key=='' && station==''){
                this.dataTableList=[];
            }else{
                this.onMediaGroupQuery();
            }
        },

        getRowKey(row) {
            return row.groupId;
        },

        ViewImage(item){
            this.imageList = item;
            this.viewMediaVisible=true;
        },

        openStation(station){
            this.station=station;
            this.viewStationVisible=true;
        },

        onCollapse(item){
            console.log('collapse')
            if (item.collapse==1){
                item.collapse=0;
            }else{
                item.collapse=1;
            }
        },

        handleSelectionChange(val) {	
            console.log('click')			
            this.selectedList = val;				
        },
        
        checkbox_select(row, index){
            if (row.useRate<100 && row.saleStatus=='正常' && row.existMonthRate)
                return 1;
            else
                return 0;
        },

        //查询资源套餐
        async onMediaGroupQuery(){      
            const loading = this.$loading({
                lock: true,
                text: '检索中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            await this.$store.dispatch('operate/GetGroupList_ByTag',{tagIds:this.tagIds,key:this.key,station:this.station,isCityAnd:this.isCityAnd}).then(()=>{
                this.dataTableList=this.GetGroupList_ByTag;
                loading.close();
            })
        },

        //初始化并打开设置投放要素窗口
        async onSetPlan(item,flag){
            if (flag==0){
                this.curItem=item;
                // console.log('sdfasd',item)
                await this.$store.dispatch('operate/GetGroupDetail',{groupId:this.curItem.groupId}).then(()=>{                   
                    this.planData=this.GetGroupDetail[1];   
                    // if (this.curItem.typeIds*1==4){
                    //     this.curItem.fullname=this.curItem.station+' '+this.curItem.name+' '+this.curItem.medias;
                    // }else{
                        this.curItem.fullname=this.curItem.station+' '+this.curItem.name;
                    // }
                    this.toPlanList=[];                         
                })             
            }else{
                this.curItem.groupLevel=1;
                this.curItem.name='批量套餐资源';
                this.curItem.haveLed='False';
                this.curItem.haveDigitalDx='False';
                this.curItem.haveLc='False';
                this.curItem.canFree=0;
                
                var cost10=0;
                var cost15=0;
                var onetimeCost=0;
                this.selectedList.forEach(element => {
                    if (element.typeName=='LED'){
                        this.curItem.haveLed='True';                        
                        cost10=cost10+element.cost10*1;
                        cost15=cost15+element.cost15*1;
                    }else{
                        cost10=cost10+element.cost*1;
                    }
                    // onetimeCost = onetimeCost + element.onetimeCost*1;
                    element.fullname = element.station+' '+element.name;
                });                
                this.curItem.cost10=cost10;
                this.curItem.cost15=cost15;
                this.curItem.cost=cost10;
                // this.curItem.onetimeCost = onetimeCost;
                this.toPlanList = this.selectedList;
                
                // console.log(this.selectedList)
            }  
            this.setPlanVisible=true;  
            // console.log('aaa',this.toPlanList)
        },

       
      

        // 每页多少条
        handleMediaSizeChange(val) {
            this.mediaGroup.pageSize = val;
        },
        // 当前页
        handleMediaCurrentChange(val) {
            this.mediaGroup.currentPage = val;
        },

        //通知子组件已完成，可以显示父组件中的table
        setFinished(){
            this.finished=true;
        },


        open(){
            // console.log('do open')
            this.$nextTick(() => {
            //  执行echarts方法
            
            })
        },
    }
};
</script>

<style scoped>

/* .prog-ele >>> .el-progress .el-progress-circle{
    width:66px;
    height:66px;
} */
.legendImg >>>.el-image__inner{
    /* width: 40%; */
}
.sub-title{
    font-size: large;
    margin-top:4px;
}
.item-pre{
    font-size: larger;
}
.button-footer{
    display: flex;
    flex-direction:row-reverse;
}
.product-name{
    font-size: xx-large;
    margin-top:-10px;
}
.card-group{
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
}
.group-item{
    /* padding: 20px; */
    margin-left: 10px;
    
}
.box-card {
    width: 480px;
    
  }
   .text {
    font-size: 14px;
  }

  .item {
    /* margin-bottom: 18px; */
    margin-top:18px;
  }

  /* .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  } */
 

.transition-box {
    margin-bottom: 10px;
    width: 200px;
    height: 100px;
    border-radius: 4px;
    background-color: #409EFF;
    text-align: center;
    color: #fff;
    padding: 40px 20px;
    box-sizing: border-box;
    margin-right: 20px;
  }

.schart-box {
    display: inline-block;
    margin: 20px;
}
.schart {
    width: 600px;
    height: 400px;
}
.content-title {
    clear: both;
    font-weight: 400;
    line-height: 50px;
    margin: 10px 0;
    font-size: 22px;
    color: #1f2f3d;
}


.grid-content {
    display: flex;
    align-items: center;
    flex-direction:column;
    /* height: 120px; */
}

.grid-subcontent {
    display: flex;
    align-items: center;
    height: 50px;
}

.grid-cont-right {
    flex: 1;
    text-align: center;
    font-size: 14px;
    color: #999;
}

.grid-num {
    font-size: 15px;
    /* font-weight: bold; */
}

.grid-num-small {
    font-size: 16px;
    font-weight: bold;
}

.grid-subnum {
    font-size: 20px;
    font-weight: bold;
}

.grid-con-icon {
    font-size: 50px;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    color: #fff;
    border-radius: 50%;
    /* margin-left: 80px; */
}
.grid-subcon-icon{
    font-size: 30px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    color: #fff;
}

.grid-con-0 .grid-subcon-icon {
    background: rgb(184, 223, 223);
}

.grid-con-0 .grid-num {
    color: rgb(184, 223, 223);
}

.grid-con-1 .grid-con-icon {
    background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
    background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
    background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
    color: rgb(242, 94, 67);
}

.grid-con-4 .grid-con-icon {
    background: rgb(132, 94, 67);
}

.grid-con-4 .grid-num {
    color: rgb(132, 94, 67);
}

.grid-con-5 .grid-con-icon {
    background: rgb(66, 94, 67);
}

.grid-con-5 .grid-num {
    color: rgb(66, 94, 67);
}

.grid-con-6 .grid-con-icon {
    background: rgb(66, 136, 67);
}

.grid-con-6 .grid-num {
    color: rgb(66, 136, 67);
}

.clearfix{
    margin-top: -5px;
}
.subtitle{
    font-weight: bolder;
    padding-right: 5px;
}


</style>